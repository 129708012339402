<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

import Multiselect from "vue-multiselect";
import { VueTelInput } from 'vue-tel-input';
import vue2Dropzone from "vue2-dropzone";
import {authMethods} from "@/state/helpers";
import {getFcmToken,} from "@/plugins/messagingGetToken";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
/**
 * Register component
 */
export default {
  props: {
    isManagement: {
      type: [Boolean],
      default: () => {
        return false;
      },
    },
    status: {
      type: [String],
      default: () => {
        return "";
      },
    },
    enableManager : {
      type: [Boolean],
      default: () => {
        return false;
      },
    }
  },
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  middleware: "authentication",
  components: {
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueTelInput,
  },
  data() {
    return {
      isHidden: false,
      isShow: false,
      processing: false,
      locations: [],
      loc_id: [],
      user: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        phone: null,
        experience : null,
        type : null,
      },
      submitted: false,
      tryingToRegister: false,
      isRegisterError: false,
      title: "Register",
      work_area: [],
      areas: [],
      emp_job_type: null,
      under_eighteen_docs: null,
      work_area_role: null,
      options: ["Sat", "Sun", "Mon", "Tues", "Wed", "Thur", "Fri"],
      workArea: [],
      dropzoneOptions: {
        // url: (file)=>this.under_eighteen_docx=file[0],
        url: this.$axios.defaults.baseURL + "/store-file",
        thumbnailWidth: 300,
        maxFiles:1,
        // autoProcessQueue:false,
        headers: {
          "My-Awesome-Header": "header value",
        },
        addRemoveLinks: true,
      },
      fcm_tokenData:null,
      phoneError : true,
      action_detail : null,
      availibilties: [],
      payment: null,
      selected_payment : null,
    };
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      confirm_password: {
        required,
      },
      phone: {
        required,
      },
      experience : {
        required,
      }
    },
    emp_job_type: {
      required,
    },
    work_area_role: {
      required,
    },
    work_area: {
      required,
    },
    loc_id: {
      required,
    },
  },

  mounted() {
    document.body.classList.add("authentication-bg");
    this.getAvailiabilties();
    if (this.currentUser != null && this.isManager()) {
      this.fetchLocationsForManager();
    } else {
      this.fetchLocations();
    }

    if(!this.isManagement)
    {
      this.requestNotification();
    }
  },

  watch : 
  {
    'user.experience' : {
      handler : function(newVal,oldVal) {
        if(newVal < 0)
        {
          this.user.experience = 1;
        }
      },
      deep: true
    },

    'user.type' : {
      handler : function(newVal,oldVal) {
        if(this.user.type == 'manager')
        {
          this.emp_job_type = 'Manager';
        }
      }
    },
  },
  methods: {
    ...authMethods,
    requestNotification() {
      getFcmToken()
        .then((fcm_token) => {
          this.fcm_tokenData = fcm_token;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAvailiabilties()
    {
      this.$axios.get('get-static-data' , {params: {types: JSON.stringify(["availiabilty"]) }}).then((response) => {
        let data = response.data.payload.data;
        this.availibilties = data.availiabilty;
      }).catch((error) => {
      this.handleErrorResponse(error.response, "error");
      });
    },
    tryToRegisterIn() {
      let self = this;
      self.submitted = true;
      self.$v.$touch();
      if (self.$v.$invalid) {
        return;
      }
      if (self.processing || this.phoneError == false) {
        return;
      }
      self.processing = true;

      if(self.user.type == false) 
      {
        self.user.type = null;
      }

      self.action_detail = this.isManagement ? null : 'Your profile is being under review. Your request will be approved shortly';
      self.$axios
        .post("/employee/register", {
          name: self.user.name,
          email: self.user.email,
          password: self.user.password,
          confirm_password: self.user.confirm_password,
          phone: self.user.phone,
          payment: self.payment,
          experience_level: self.user.experience,
          type : self.user.type,
          status: this.status,
          fcm_token: this.fcm_tokenData,
          work_area: self.work_area.map((item) => {
            return item.name;
          }),
          emp_job_type: self.emp_job_type,
          minor: self.isHidden,
          under_eighteen_docs: self.under_eighteen_docs,
          location_id: self.loc_id.map((item) => {
            return item.id;
          }),
          // under_eighteen_docs:self.$refs.myVueDropzone.getQueuedFiles(),
          work_area_role_id: self.work_area_role.map((item) => {
            return item.id;
          }),
          action_detail: self.action_detail,
        })
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          if (this.isManagement) {
            if(this.user.type == 'manager' && this.emp_job_type == 'Manager')
            self.$router.push("/managers-listing");
            else
            self.$router.push("/team-member-record");
          } else {
            this.saveUser(data.payload.user);
            this.sendOtp(data.payload.user.token);
            // window.location.href = "/employee-profile";
          }
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(function () {
          self.processing = false;
        });
    },
    sendOtp(token){
      const config = {
        headers: { Authorization: `Bearer `+ token }
      };
      this.$axios.post('/user/send-otp' ,{phone : this.user.phone} , config).then((response)=> {
        this.sentMessage = response.data.message;
        this.$router.push({ name: 'verify-phone-number', query: {phone: this.user.phone , token : token}});
      })
    },

    fileUploaded(file) {
      this.under_eighteen_docs = file.xhr.response;
    },

    fetchLocations() {
      this.$axios
        .get("/location/index")
        .then((response) => {
          let data = response.data.payload;
          this.locations = data;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
    fetchLocationsForManager() {
      this.$axios
        .get("/restaurant/location/index")
        .then((response) => {
          let data = response.data.payload;
          this.locations = data;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },
    fetchArea(e) {
      this.areas.push(...e.areas);
      if (this.areas.length <= 0) {
        if(this.isManagement){
          this.triggerSwal("To procced further please create work area against this location","error");
        }else{
          this.triggerSwal("This location is in maintenance. Please select other location","error");
        }
      }
    },

    removeArea(e) {
      this.areas = this.areas.filter((item) => item.location_id != e.id);
      this.work_area = this.work_area.filter((item) => {
        this.work_area_role = this.work_area_role.filter((ele) => (ele.area_id != item.id)); //deselect the selected roles.
        this.workArea = this.workArea.filter((ele) => (ele.area_id != item.id)); //remove roles from dropdown without selecting a workarea.
        item.location_id != e.id
      });
    },

    showRole(e) {
      this.workArea.push(...e.area_role);
    },
    removeRole(e) {
      this.workArea = this.workArea.filter((item) => item.area_id != e.id);
      this.work_area_role = this.work_area_role.filter((item) => (item.area_id != e.id));
    },
    numberFormat(e)
    {
        return this.user.experience = e.substring(0,2);
    },
    checkPhone(phone)
    {
      if(this.user.phone)
      this.phoneError = phone.valid; 
    }
  },
};
</script>

<template>
  <div>
    <b-form @submit.prevent="tryToRegisterIn">
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            label="Full Name"
            label-for="formrow-fullname-input"
            class="mb-3"
          >
            <b-form-input
              type="text"
              id="formrow-fullname-input"
              v-model="user.name"
              placeholder="Enter full name"
              :class="{
                'is-invalid': submitted && $v.user.name.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && $v.user.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.user.name.required">Name is required.</span>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            id="fullname-group"
            label="Email"
            label-for="email"
            class="mb-3"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              type="email"
              placeholder="Enter email"
              :class="{
                'is-invalid': submitted && $v.user.email.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && $v.user.email.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.user.email.required">Email is required.</span>
              <span v-if="!$v.user.email.email">Please enter valid email.</span>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            id="password-group"
            label="Password"
            class="mb-3"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="user.password"
              type="password"
              placeholder="Enter password"
              :class="{
                'is-invalid': submitted && $v.user.password.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.user.password.required"
              class="invalid-feedback"
            >
              Password is required.
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            id="password-group"
            label="Confirm Password"
            class="mb-3"
            label-for="Confirm Password"
          >
            <b-form-input
              id="password"
              v-model="user.confirm_password"
              type="password"
              placeholder="Confirm Password"
              :class="{
                'is-invalid': submitted && $v.user.confirm_password.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.user.confirm_password.required"
              class="invalid-feedback"
            >
              Confirm Password is required.
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label>Availability</label>
          <b-input-group >
            <select v-model="emp_job_type"  id="emp_job_type" class="form-select" 
                    :class="{'is-invalid': submitted && $v.emp_job_type.$error}" :disabled="user.type == 'manager'">
              <option :value="i" v-for="(availibilty, i) in availibilties" :key="i">{{availibilty}}</option>
            </select>
            <b-input-group-prepend is-text class="w-25" v-if="enableManager">
                  <b-form-checkbox  value='manager' v-model="user.type" checked plain >Manager</b-form-checkbox>
            </b-input-group-prepend>
            <div
                 v-if="submitted && !$v.emp_job_type.required"
                class="invalid-feedback"
                >
                Availibilty is required.
            </div>
          </b-input-group>

        </div>
        <div class="col-md-6">
          <b-form-group
            id="example-tel"
            label="Phone Number"
            label-for="tele"
            class="mb-3"
          >
            <vue-tel-input v-model="user.phone" :autoFormat="true" :dropdownOptions="{showFlags:true, showSearchBox:true}"  :mode="'international'" @validate="checkPhone($event)" :validCharactersOnly="true"
            :class="{
                'is-invalid': submitted && $v.user.phone.$error,
              }"></vue-tel-input>
            <div
              v-if="submitted && !$v.user.phone.required"
              class="invalid-feedback"
            >
              Phone Number is required.
            </div>
            <div  v-if="!phoneError"
              class="text-danger mt-1"
            >
              Invalid Phone Number.
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            id="example-tel"
            label="Experience"
            label-for="experience-level"
            class="mb-3"
          >
            <b-form-input :formatter="numberFormat" type="number" placeholder="Enter Experience"  min="1" 
              v-model="user.experience" :class="{
                'is-invalid': submitted && $v.user.experience.$error,
              }"></b-form-input>

            <div
              v-if="submitted && !$v.user.experience.required"
              class="invalid-feedback"
            >
              Experience is required.
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" >
          <label>Select Location</label>
          <multiselect
            :class="{ 'is-invalid': submitted && $v.loc_id.$error }"
            v-model="loc_id"
            :options="locations"
            label="name"
            track-by="id"
            :multiple="true"
            @select="fetchArea($event)"
            @remove="removeArea($event)"
          ></multiselect>
          <div v-if="submitted && !$v.loc_id.required" class="invalid-feedback">
            Location is required.
          </div>
        </div>
        <div class="col-md-6 mb-3" v-if="areas.length > 0">
          <label>Work Area</label>
          <div class="">
            <multiselect
              :class="{ 'is-invalid': submitted && $v.work_area.$error }"
              v-model="work_area"
              :options="areas"
              label="name"
              track-by="id"
              :multiple="true"
              @select="showRole($event)"
              @remove="removeRole($event)"
            ></multiselect>

            <div
              v-if="submitted && !$v.work_area.required"
              class="invalid-feedback"
            >
              Work Area is required.
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="workArea.length > 0">
          <label>Select Role/Position</label>
          <multiselect
            :class="{
              'is-invalid': submitted && $v.work_area_role.$error,
            }"
            v-model="work_area_role"
            :options="workArea"
            label="name"
            track-by="id"
            :multiple="true"
          ></multiselect>
          <span class="span">You can select multiple role/positions</span>
          <div
            v-if="submitted && !$v.work_area_role.required"
            class="invalid-feedback"
          >
            Role/Position is required.
          </div>
        </div>
      </div>

      <div v-if="this.isManagement">
        <div class="mb-3">
            <p>Select Salary Option</p>
            <select class="form-select" v-model="selected_payment">
              <option value="base_salary">Base salery per hour</option>
              <option :value="null">Role salary per hour</option>
            </select>
          </div>
  
          <div  v-if="selected_payment== 'base_salary'">
              <div class="input-group mb-3">
                  <span class="input-group-text">$</span>
                  <input type="number"  required placeholder="Enter hourly payment" v-model="payment"  class="form-control" />
              </div>
          </div>

      </div>


    <div v-if="user.type != 'manager'" class="d-none">
      <div class="row">
        <div class="col-md-12 my-2">
          <h6>Please answer these following question.</h6>
          <p class="note primaryColor roboto" v-if="!isManagement">
            NOTE: Please wait for approval, you will receive notification via
            Email/Text.
          </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label>{{!isManagement ? 'Are you' : 'Does employee'}} under the age of 18?</label>
          <div class="group-button d-flex gap-2">
            <a
              type="button"
              class="m-0 rounded-pill px-4 py-2 fw-bold"
              :class="
                isHidden == true
                  ? 'register-btn-active text-white'
                  : 'outline-button'
              "
              @click="isHidden = true"
              >Yes</a
            >
            <a
              type="button"
              class="m-0 rounded-pill px-4 py-2 fw-bold"
              :class="
                isHidden == false
                  ? 'register-btn-active text-white'
                  : 'outline-button'
              "
              @click="isHidden = false"
              >No</a
            >
          </div>
        </div>
      </div>
      <div class="row mb-3" v-show="isHidden">
        <div class="col-md-12">
          <label
            >{{!isManagement ? 'Do you' : 'Does employee'}} have a work authorization that allows {{!isManagement ? 'you' : 'him/her'}} to work during
            regular school hours & eliminates the weekly hour restrictions as
            set by the State of Florida?
          </label>
          <div class="d-flex gap-2 align-items-center">
            <a
              type="button"
              class="m-0 rounded-pill px-4 py-2 fw-bold"
              :class="
                isShow == true
                  ? 'register-btn-active text-white'
                  : 'outline-button'
              "
              @click="isShow = true"
              >Yes</a
            >
            <a
              type="button "
              class="m-0 rounded-pill px-4 py-2 fw-bold"
              :class="
                isShow == false
                  ? 'register-btn-active text-white'
                  : 'outline-button'
              "
              @click="isShow = false"
              >No</a
            >
          </div>
          <vue-dropzone
            v-show="isShow"
            class="w-25 mt-3"
            id="dropzone"
            ref="myVueDropzone"
            :use-custom-slot="true"
            :options="dropzoneOptions"
            @vdropzone-success="fileUploaded"
          >
            <div class="dropzone-custom-content">
              <p class="mb-0 pb-0 f-12">
                <img
                  src="@/assets/images/upload-file.svg"
                  class="mb-0 pb-0"
                  alt=""
                />
                Please Upload a copy of work authorization.
              </p>
            </div>
          </vue-dropzone>
        </div>
      </div>
    </div>
    <SubmitButton type="submit" text="Register" :processing="processing"></SubmitButton>
      <div class="mt-3" v-if="!isManagement">
        <p class="mb-0 already-account">
          Already have an account ?
          <router-link to="/login" class="fw-bold primaryColor"
            >Login</router-link
          >
        </p>
      </div>
    </b-form>
    <!-- end row -->
  </div>
</template>

<style lang="scss" module></style>

