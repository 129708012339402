<script>

import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import CreateForm from "./components/form.vue";
import appConfig from "@/app.config";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
/**
 * Register component
 */
export default {

  data(){
      return{
        title: 'Create Account',
        items:null,
        status : 'Active',
        enableManager : false,
      }
  },
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,CreateForm
  },

  mounted() {
    if(this.isRestaurantOwner())
    {
      this.enableManager = true;
    }else{
      this.enableManager = false;
    }
  }
  
};

</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
           <div class="card">
            <div class="card-body">
                <div class="row align-items-center radius-10">
  
    <CreateForm :isManagement="true" :status="status" :enableManager="enableManager"></CreateForm>
    <!-- end row -->
  </div>
  </div>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>

